
















































































import './styles/FindMemberForm.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IFindMemberPostData } from '@store/modules/project-team/interfaces/ApiRequests';
import { IProjectState, IMarkKit, IProject } from '@store/modules/project/Interfaces';
import { ProjectTeamActions } from '@store/modules/project-team/Types';
import { IUserState } from '@store/modules/user/Interfaces';
import { ICategory } from '@store/modules/project-documents/Interfaces';
import { formatDate } from '@utility/formatDate';
import { makeFocusInput } from '@utility/makeFocusInput.js';
import { convertFileToBase64 } from '@utility/fileConverter';
import { IJuristicEntity } from "@store/modules/about-project/Interfaces";

const NSProject = namespace('storeProject');
const NSUser = namespace('storeUser');
const NSProjectTeam = namespace('storeProjectTeam');
const NSDocuments = namespace('storeProjectDocuments');
const NSAboutProject = namespace('storeAboutProject');

class InitialFindMemberFormData {
    POST_DATA_ROLE = 'customer';
    workName: string = '';
    documentCategory = {} as ICategory;
    workTypes: IMarkKit[] = [];
    task: string = '';
    dates = {
        worksCompletionDeadline: '',
        orderPostingDeadline: '',
    };
    files: File[] = [];
}

class InitialFindMemberErrors {
    workName: boolean = false;
    documentCategory: boolean = false;
    workTypes: boolean = false;
    task: boolean = false;
    worksCompletionDeadline: boolean = false;
    orderPostingDeadline: boolean = false;
    files: boolean = false;
}

@Component({
    name: 'FindMemberForm',

    components: {
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseTextarea: () => import('@components/BaseInputs/BaseTextarea.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
    },
})
export default class FindMemberForm extends Vue {
    $refs!: {
        fileInput: HTMLFormElement,
        findMemberForm: HTMLElement,
    }

    @NSUser.Getter('userData') userData!: IUserState;
    @NSProject.Getter('projectId') projectId!: number;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSDocuments.Getter('categoryList') categoryList!: ICategory[];
    @NSProject.State((state: IProjectState) => state.projectMarkKits) markKits!: IMarkKit[];
    @NSProjectTeam.Action(ProjectTeamActions.A_FIND_MEMBER) findMember!: (payload: { params: IFindMemberPostData }) => Promise<void>;
    @NSAboutProject.Getter('juristicEntities') juristicEntities!: IJuristicEntity[];

    data = new InitialFindMemberFormData();
    errors: any = new InitialFindMemberErrors();
    errorText: string = 'Вы не заполнили одно или несколько обязательных полей';
    realtimeValidation: boolean = false;
    taskText: string = '';

    @Watch('documentCategory')
    @Watch('worksCompletionDeadline')
    @Watch('workType')
    @Watch('juristicEntities')
    watchTaskText() {
        this.data.task = this.taskTemplate;
        this.checkValidation();
    }

    mounted() {
        this.data.task = this.taskTemplate;
        makeFocusInput(this.$refs.findMemberForm);
    }

    get dateToday() {
        return formatDate({ date: new Date(), format: 'yyyy-mm-dd'});
    }

    get categoryListAsOptions(): ICategory[] {
        const categoryList = this.categoryList.filter(item => item.title !== 'Размеченный чертеж');
        const output = categoryList.map((category) => ({ ...category, label: category.title }));
        output.push({
            id: 999,
            label: 'Исполнительная документация',
            title: 'Исполнительная документация',
        });
        return output;
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get taskTemplate() {
        return `Для ${this.documentCategory} по разделам ${this.workType} проекта «${this.projectName}».
Задание: ${this.taskText}
Срок выполнения работы: ${this.worksCompletionDeadline}
Адрес объекта: ${this.addressObject}
${this.labelTaskProjectCustomer} проекта: ${this.juristicCustomer}
Сроки проекта: ${this.projectTimeline}`;
    }

    get documentCategory() {
        return this.data.documentCategory.title || '<Категория документа>';
    }

    get workType() {
        return this.data.workTypes.length
            ? this.data.workTypes.map((work) => work.title).join(', ')
            : '<виды работ>' ;
    }

    get projectName() {
        return this.projectData.projectName;
    }

    get worksCompletionDeadline() {
        return this.data.dates.worksCompletionDeadline
            ? formatDate({
                date: new Date(this.data.dates.worksCompletionDeadline),
                format: 'dd-mm-yyyy',
            })
            : '<Срок выполнения работ>';
    }

    get addressObject(): string {
        return this.projectData?.address || '<Адрес объекта>';
    }

    get startDateProject(): string {
        let startDate: string = '<Дата начала>';
        if (this.projectData?.startDate) {
            startDate = formatDate({date: new Date(this.projectData.startDate), format: 'dd-mm-yyyy'})
        }
        return startDate;
    }

    get endDateProject():string {
        let endDate: string = '<Дата окончания>';
        if (this.projectData?.endDate) {
            endDate = formatDate({date: new Date(this.projectData.endDate), format: 'dd-mm-yyyy'})
        }
        return endDate;
    }

    get projectTimeline(): string {
        let timeline: string = '';
        if (!this.projectData?.startDate && !this.projectData?.endDate) {
            timeline = '<Сроки проекта>';
        } else {
            timeline = `с ${this.startDateProject} по ${this.endDateProject}`;
        }
        return timeline;
    }

    get juristicCustomer(): string {
        let juristicPersonsCustomer = this.juristicEntities.filter((entity: IJuristicEntity) => {
            return entity.role?.name.toLowerCase().includes('инвестор/заказчик')
        }).map((entity: IJuristicEntity) => entity.title).join(', ');
        return juristicPersonsCustomer || '<наименование ЮЛ с ролью Заказчик>'
    }

    get labelTaskProjectCustomer() {
        return this.juristicCustomer.split(',').length === 1 ? 'Заказчик' : 'Заказчики';
    }

    changeDocumentsCategory(category: ICategory): void {
        this.data.documentCategory = category;
    }

    updateFiles(files: File[]): void {
        this.data.files = files;
        this.checkValidation();
    }

    changeTaskText() {
        this.taskText = this.findTaskText(this.data.task).trim();
        this.checkValidation();
    }

    findTaskText(text: string): string {
        let taskDescription: string = text;
        const wordOfDefineStartTaskText: string = 'Задание:';
        const startIndexTaskText: number = text.indexOf(wordOfDefineStartTaskText);
        const endIndexTaskText: number = this.findEndIndexTaskText(text);

        if (startIndexTaskText !== -1) {
            taskDescription = text.slice(startIndexTaskText + wordOfDefineStartTaskText.length, endIndexTaskText);
        }

        return taskDescription;
    }

    findEndIndexTaskText(text: string): number {
        const wordsOrderOfDefineEndTaskText: string[] = [
            'Срок выполнения работы:',
            'Адрес объекта:',
            `${this.labelTaskProjectCustomer}:`,
            'Сроки проекта:',
        ];
         return wordsOrderOfDefineEndTaskText
            .map((word: string) => text.lastIndexOf(word))
            .find((index: number) => index > -1) || text.length - 1;
    }

    async sendForm(): Promise<void> {

        this.validateFields();
        if (!this.errorExists) {
            const files: any[] = [];
            let convertedFilesPromise: Promise<any>[] = [];
            let data: IFindMemberPostData = {
                title: this.data.workName,
                document_type: this.data.documentCategory.title,
                job_types: this.data.workTypes.map((type) => type.id),
                description: this.data.task,
                completion_date: this.data.dates.worksCompletionDeadline,
                order_placement_date: this.data.dates.orderPostingDeadline,
                project_id: this.projectId,
                user: {
                    id: this.userData.userInfo.id,
                    phone: this.userData.userInfo.phone,
                    email: this.userData.userInfo.login,
                    name: this.userData.userInfo.fullName,
                    role: this.data.POST_DATA_ROLE,
                },
                files: [],
            };
            convertedFilesPromise = this.data.files.map((fileObj) => {
                const splitTitle = fileObj.name.split('.');
                return convertFileToBase64(fileObj).then(file => {
                    files.push({
                        name: fileObj.name.replace(`.${splitTitle[splitTitle.length - 1]}`, ''),
                        format: splitTitle[splitTitle.length - 1],
                        data: file,
                    });
                });
            })

            Promise.all(convertedFilesPromise)
                .then(() => {
                    data.files = files;
                    this.findMember(
                        { params: data }
                    ).then(() => {
                        this.$emit('success');
                    });
                });
        } else {
            return Promise.reject(this.errorText)
        }
    }

    resetData() {
        this.realtimeValidation = false;
        this.errors = new InitialFindMemberErrors();
        this.data = new InitialFindMemberFormData();
    }

    validateFields() {
        this.errors.workName = !this.data.workName;
        this.errors.documentCategory = !this.data.documentCategory.title;
        this.errors.workTypes = !this.data.workTypes.length;
        this.errors.task = !this.data.task;
        this.errors.worksCompletionDeadline = !this.data.dates.worksCompletionDeadline;
        this.errors.orderPostingDeadline = !this.data.dates.orderPostingDeadline;
        this.errors.files = !this.data.files.length;

        this.realtimeValidation = true;
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }
}
